<template>
  <div class="hero-image" v-if="!empty">
    <div v-if="images && images.length > 0" class="hero-image__images">
      <SfImage
        v-for="(img, idx) in images"
        :key="idx"
        class="hero-image__images__image"
        image-tag="nuxt-img"
        :src="isDesktop ? img.desktop : img.mobile"
        :alt="img.alt"
        :title="img.alt"
        width=""
        height=""
        placeholder=""
      />
    </div>
    <div v-else-if="image" class="hero-image__image">
      <SfImage
        class="hero-image__image__image"
        image-tag="nuxt-img"
        :src="isDesktop ? image.desktop : image.mobile"
        :alt="image.alt ? image.alt : ''"
        :title="image.alt"
        width=""
        height=""
        placeholder=""
      />
    </div>
    <div class="hero-image__content">
      <div v-if="intro" class="hero-image__content__intro">{{ intro }}</div>
      <div v-if="title" class="hero-image__content__title">{{ title }}</div>
      <div v-if="link" class="hero-image__content__cta">
        <CustomCta
          :link="localePath(link['link-url'])"
          :target="link.target || '_blank'"
        >
          {{ link['link-label'] }}
        </CustomCta>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';
import { CustomCta } from '~/components/General';
import { SfImage } from '@storefront-ui/vue';

export default defineComponent({
  name: 'HeroImage',
  components: {
    SfImage,
    CustomCta,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { isDesktop } = useWindow();
    const heroImageData = computed(() =>
      Object.keys(props.data).length > 0
        ? {
            intro: props.data.subtitle,
            title: props.data.title,
            images: props.data?.images ? Object.values(props.data.images) : [],
            image: props.data.image,
            link: props.data.link,
            empty: false,
          }
        : {
            intro: null,
            title: null,
            images: [],
            link: null,
            image: null,
            empty: true,
          }
    );
    return {
      ...heroImageData.value,
      isDesktop,
    };
  },
});
</script>

<style lang="scss" scoped>
.hero-image {
  background-color: var(--c-black);
  position: relative;
  min-height: 48rem;
  overflow: hidden;
  &__images {
    width: 100%;
    min-height: 48rem;
    display: flex;
    justify-content: space-evenly;
    &__image {
      max-width: 24%;
      padding: 0 0.3125rem;
      &:nth-child(1) {
        padding-top: 2.125rem;
      }
      &:nth-child(2) {
        padding-bottom: 2.125rem;
        align-self: end;
      }
      &:nth-child(3) {
        padding-top: 4rem;
      }
      &:nth-child(4) {
        padding-bottom: 4rem;
        align-self: end;
      }
      ::v-deep img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  &__image {
    display: flex;
    width: 100%;
    height: 100%;
    &__image {
      overflow: hidden;
      object-fit: cover;
      width: 100%;
      max-height: 48rem;
      ::v-deep img {
        width: 100%;
        height: 48rem;
        object-fit: cover;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &__intro {
      @include label-l;
      color: var(--c-white);
      max-width: 12.625rem;
      text-align: center;
    }
    &__title {
      @include desktop-h2;
      color: var(--c-white);
      display: flex;
      justify-content: center;
      text-align: center;
      &:not(:first-child) {
        padding-top: 1.2813rem;
      }
    }
    &__cta {
      &:not(:first-child) {
        padding-top: 1.875rem;
      }
    }
  }
  @include to-tablet-max {
    min-height: 44.75rem;
    &__images {
      min-height: 44.75rem;
      display: grid;
      grid-template-columns: auto auto;
      &__image {
        width: auto;
        max-width: unset;
        height: auto;
        max-height: auto;
        &:nth-child(1) {
          padding: 1.25rem 0.3125rem 0 1.25rem;
        }
        &:nth-child(2) {
          padding: 3.75rem 1.25rem 0 0.3125rem;
          align-self: normal;
        }
        &:nth-child(3) {
          padding: 0 0.3125rem 3.75rem 1.25rem;
          align-self: end;
        }
        &:nth-child(4) {
          padding: 0 1.25rem 1.25rem 0.3125rem;
          align-self: end;
        }
      }
    }
    &__image {
      &__image {
        max-height: 44.75rem;
      }
      ::v-deep img {
        height: 44.75rem;
      }
    }
    &__content {
      padding: 1.25rem;
      &__intro {
        max-width: 100%;
        margin: 0 1.25rem;
      }
      &__title {
        @include mobile-h2;
        max-width: 100%;
        &:not(:first-child) {
          padding-top: 1.25rem;
        }
      }
    }
  }
}
</style>
